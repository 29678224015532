import { dev } from '$app/environment';
import { PUBLIC_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { getClientApp } from '$lib/firebase';
import * as Sentry from '@sentry/sveltekit';
import { Replay, handleErrorWithSentry } from '@sentry/sveltekit';
import { getAuth } from 'firebase/auth';
//import { PUBLIC_GIT_COMMIT_SHA, PUBLIC_HIGHLIGHT_PROJECT_ID } from '$env/static/public';
//import { H } from 'highlight.run';

const auth = getAuth(getClientApp());

Sentry.init({
	enabled: !dev,
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1.0,
	environment: PUBLIC_ENV,
	replaysSessionSampleRate: PUBLIC_ENV === 'production' ? 1.0 : 0.0,
	replaysOnErrorSampleRate: PUBLIC_ENV === 'production' ? 1.0 : 0.0,
	tracePropagationTargets: ['localhost', /^https:\/\/(dev)?\.trystorytime\.com/],
	integrations: [new Replay(), new Sentry.BrowserTracing()],
});

auth.onAuthStateChanged((user) => {
	if (!user) {
		Sentry.setUser(null);
		return;
	}

	Sentry.setUser({
		id: user.uid,
		email: user.email ?? undefined,
	});
});

//if (!dev) {
//	H.init(PUBLIC_HIGHLIGHT_PROJECT_ID, {
//		environment: dev ? 'development' : 'production',
//		version: PUBLIC_GIT_COMMIT_SHA,
//		tracingOrigins: true,
//		networkRecording: {
//			enabled: true,
//			recordHeadersAndBody: true,
//			urlBlocklist: [
//				// insert full or partial urls that you don't want to record here
//				// Out of the box, Highlight will not record these URLs (they can be safely removed):
//				'https://www.googleapis.com/identitytoolkit',
//				'https://securetoken.googleapis.com',
//			],
//		},
//	});
//}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
